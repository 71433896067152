import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import logo from "../assets/6.png"
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import "./style.css";

const Contact = () => {
  return (
    <div className='contact' id="Contact" style={{padding:"2rem",  display:"flex", flexDirection:"row"}}>
        <div style={{marginBottom:"1rem", marginLeft:"4rem", backdropFilter: "blur(0.05rem)", width:"40rem",height:"40rem", zIndex:"1",}}>
          <CardMedia component="img" image={logo} alt="about"/>
        </div>
        <Card style={{marginTop:"15rem", paddingTop:"0.2rem", width:"35rem", height:"15rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.7))",
          boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
          backdropFilter: "blur(2rem)", zIndex:"1", marginRight:"4rem"}}>
        <CardActionArea>
          <CardContent>
            <Typography style={{paddingTop:"1rem", marginLeft:"7rem"}} gutterBottom variant="h5" component="div">
              İLETİŞİM
            </Typography>
            <Typography style={{paddingTop:"1rem",marginLeft:"2rem"}} variant="body2" color="black">
                Telefon: +90 541 383 21 01
            </Typography>
            <Typography style={{paddingTop:"1rem", marginLeft:"2rem"}} variant="body2" color="black">
                Adress: Cemalliye Mahallesi Salih Omurtak Caddesi No: 210 Daire: 328 Çorlu/Tekirdağ
            </Typography>
            <Typography style={{paddingTop:"1rem",marginLeft:"2rem"}} variant="body2" color="black">
                E-mail: info@feriselektronik.com
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
}

export default Contact