import React from "react";
import { Navbar, Home, Content, Contact, Footer } from "./components";
import './App.css';
import logo from "./components/assets/6.png";

const App = () => {
  return (
    <div className="App">
      <img style={{opacity:"0.1", zIndex:"-1", top:"0", left:"12rem", width:"50rem", height:"50rem", position:"fixed"}} src={logo} alt="" />
      <Navbar />
      <Home />
      <Content />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
