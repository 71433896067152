import React from 'react';
import "./style.css";
import logo from "../assets/6.png"

const Navbar = () => {

  return (
    <div className='nav'>
      <div className='logo'>
        <a href='#home'>
        <img style={{width:"100px", height:"100px", padding:"5px", marginBottom:"10px"}} src={logo} alt="logo"/>
        </a>
      </div>
      <div className='link'>
        <p>
          <a href='#home'>Ana Sayfa</a> 
        </p>
        <p>
          <a href='#About'>Hakkımızda</a> 
        </p>
        <p>
          <a href='#Contact'>İletişim</a> 
        </p>
      </div>
    </div>
  )
}

export default Navbar