import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import about from "../assets/about.png";
import vison from "../assets/vizyon.jpg";
import hisse from "../assets/hisse.jpg";
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import "./style.css";

const Content = () => {
  return (
    <div className='content' id='About'>
      <div className='about'>
      <Card style={{marginTop:"3rem",marginLeft:"1rem", width:"35rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.7))",
        boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
        backdropFilter: "blur(5rem)", paddingBottom:"2rem"}}>
        <CardActionArea>
          <CardContent>
            <Typography style={{paddingTop:"1rem"}} gutterBottom variant="h5" component="div">
              HAKKIMIZDA
            </Typography>
            <Typography style={{paddingTop:"1rem"}} variant="body2" color="black">
              FERİS, 2018 yılında üç ortaklı olarak Tekirdağ Çorlu’da kurulmuştur.
              FERİS, başta Ar-Ge çalışmaları olmak üzere, bilişim teknolojileri, elektronik ve kimya alanlarında faaliyet göstermektedir.
              FERİS, Ar-Ge çalışmalarını yürütecek yetkinlikte teknik personel istihdamına öncelik vermektedir.
              FERİS, Ar-Ge çalışmalarının yürütülmesi için çeşitli kurum ve kuruluşlar ile çözüm ortaklığı esaslı işbirlikleri yürütmektedir.
              FERİS, Ar-Ge faaliyetlerinin finansmanını kendi özkaynaklarından sağlamaktadır.
              HELOJEX, FERİS tarafından buluşu gerçekleştirilen ilk uluslararası markadır.
            </Typography>
            </CardContent>
        </CardActionArea>
      </Card>

      <div style={{marginTop:"5.5rem", marginLeft:"18rem", width:"12rem",height:"10rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.2),rgba(255, 255, 255, 0.05))",
        boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
        backdropFilter: "blur(5rem)", paddingBottom:"2rem"}}>
          <CardMedia component="img" image={about} alt="about"/>
      </div>

      </div>
      <div className='vision' >
      <div style={{marginTop:"12.5rem", marginLeft:"8rem", width:"20rem",height:"8rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.2),rgba(255, 255, 255, 0.05))",
        boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
        backdropFilter: "blur(0.8rem)", paddingBottom:"2rem"}}>
          <CardMedia component="img" image={vison} alt="about"/>
      </div>
      <Card style={{marginTop:"10rem",marginLeft:"13rem", width:"35rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.7))",
        boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
        backdropFilter: "blur(5rem)", paddingBottom:"2rem"}}>
        <CardActionArea>
          <CardContent>
            <Typography style={{paddingTop:"1rem"}} gutterBottom variant="h5" component="div">
              VİZYONUMUZ
            </Typography>
            <Typography style={{paddingTop:"1rem"}} variant="body2" color="black">
              FERİS, Ar-Ge çalışmalarını yürüttüğü ve üretimini gerçekleştirdiği ürünler yelpazesinde insanların hayatını kolaylaştırmayı, yaşam kalitelerini arttırmayı, bayanları daha zarif kılmayı, erkeklerin harika hissetmelerini hedefleyen uluslararası bir firmadır.
              FERİS, sunduğu her üründe, ürünün yer aldığı pazarda kalite ve standartları yükseltmeyi hedeflemektedir.
              FERİS, Ar-Ge çalışmaları ile geliştirdiği ürünlerin ülke ekonomisi ve şirket kaynaklarını büyütme vizyonu ile faaliyetlerini sürdürmektedir.
            </Typography>
            </CardContent>
        </CardActionArea>
      </Card>

      </div>
      <div className='hisse'>
        <Card style={{marginTop:"10rem",marginLeft:"1rem", width:"35rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.7))",
          boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
          backdropFilter: "blur(5rem)", paddingBottom:"2rem"}}>
        <CardActionArea>
          <CardContent>
            <Typography style={{paddingTop:"1rem"}} gutterBottom variant="h5" component="div">
              HİSSEDARLARIMIZ
            </Typography>
            <Typography style={{paddingTop:"1rem"}} variant="body2" color="black">
              Seyhan Yılmaz, 1978 yılında Bulgaristan Şumnu doğumludur. FERİS’ in araştırmacı kimliğinin ortaya çıkmasında liderlik eden fikir insanıdır. Çeşitli özel sektör kuruluşlarında görev aldıktan sonra araştırmacı kimliği doğrultusunda diyabetli çocukların tedavisinde destek olacak ürünü tasarlamış ve geliştirmiştir. Halen FERİS in kadın zarafetine yönelik ürünlerine liderlik etmektedir.
              İsmail Bilge, 1969 Bozkurt doğumludur. İstanbul Teknik Üniversitesinden Maden Yüksek Mühendisi olarak mezun olduktan sonra çeşitli özel sektör şirketlerinde satış pazarlama görevlerinde çalışmıştır. FERİS’ in kuruluşundan itibaren şirketin finansman ve insan kaynağı yönetimine liderlik etmektedir.
              Gökhan Demirkol, 1975 Tekirdağ doğumludur. Ondukuz Mayıs Üniversitesinden Çevre Mühendisi olarak mezun olduktan sonra, önce kamuda çevre mühendisi daha sonra özel sektörde satış sorumlusu olarak çalışmıştır. FERİS’ in kuruluşundan itibaren şirketin vizyonunun belirlenmesinde ve finansman sağlanmasında liderlik etmektedir.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <div style={{marginTop:"16rem", marginLeft:"13rem", width:"20rem",height:"10rem", background: "linear-gradient(to bottom right,rgba(255, 255, 255, 0.2),rgba(255, 255, 255, 0.05))",
        boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #fff, 1px 1px 2px #fff",
        backdropFilter: "blur(0.8rem)", paddingBottom:"2rem"}}>
          <CardMedia component="img" image={hisse} alt="about"/>
      </div>
      </div>
    </div>
  )
}

export default Content