import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import "./style.css";

const Footer = () => {
  return (
    <div className='footer'>
    <MDBFooter style={{zIndex:"1", width:"100rem"}} className='bg-dark text-white footer_mdb' >
      <div className='text_footer'>
        © 2018 :
        <a className='text-white pl-2' href='https://feriselektronik.com/'>
          Feris Teknolojileri
        </a>
        <button className='up_button' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
          <ArrowUpwardIcon fontSize="large" />
        </button>
      </div>
    </MDBFooter>
    </div>
  )
}

export default Footer