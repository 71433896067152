import React from 'react';
import "./style.css";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import home from "../assets/home.jpg"


const Home = () => {

  return (
    <div className='home' id='home'>
        <img src={home} className="home_img" alt="" />
        <div className='helojex'>
            <h1 style={{zIndex:"1", paddingTop:"5px"}}>WELCOME TO FERİS</h1>
            <h2 style={{zIndex:"1"}}>Helojex</h2>
            <CircularProgress disableShrink sx={{animationDuration: '800ms'}} style={{zIndex:"1"}} />
            <Typography style={{marginTop:"10px",marginBottom:"15px", fontSize:"1rem"}} variant="caption" component="div" color="black">
                %95
            </Typography>
        </div>
        
    </div>
  )
}

export default Home
